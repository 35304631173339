/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    '4-circle': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.519 5.057q.33-.527.657-1.055h1.933v5.332h1.008v1.107H10.11V12H8.85v-1.559H4.978V9.322c.77-1.427 1.656-2.847 2.542-4.265zM6.225 9.281v.053H8.85V5.063h-.065c-.867 1.33-1.787 2.806-2.56 4.218"/><path pid="1" d="M16 8A8 8 0 110 8a8 8 0 0116 0M1 8a7 7 0 1014 0A7 7 0 001 8"/>',
    },
});
